import React, { useEffect, useRef, useState } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import Toolbar from "./Toolbar";
import "./DraftEditor.css";

const DraftEditor = () => {
  const [editorState, setEditorState] = useState(() => {
    const savedData = localStorage.getItem("editorContent");
    if (savedData) {
      return EditorState.createWithContent(
        convertFromRaw(JSON.parse(savedData))
      );
    } else {
      return EditorState.createEmpty();
    }
  });

  const editor = useRef(null);

  useEffect(() => {
    focusEditor();
  }, []);

  const focusEditor = () => {
    editor.current.focus();
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return true;
    }
    return false;
  };

  const handleBeforeInput = (chars, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const blockKey = selectionState.getStartKey();
    const block = currentContent.getBlockForKey(blockKey);
    const blockText = block.getText();

    // Handle #
    if (blockText === "#" && chars === " ") {
      const newContentState = Modifier.replaceText(
        currentContent,
        selectionState.merge({
          anchorOffset: 0,
          focusOffset: 1,
        }),
        "",
        block.getInlineStyleAt(0)
      );

      const newState = EditorState.push(
        editorState,
        newContentState,
        "remove-range"
      );
      setEditorState(RichUtils.toggleBlockType(newState, "header-one"));
      return "handled";
    }

    // Handle *
    if (blockText === "*" && chars === " ") {
      const newContentState = Modifier.replaceText(
        currentContent,
        selectionState.merge({
          anchorOffset: 0,
          focusOffset: 1,
        }),
        "",
        block.getInlineStyleAt(0)
      );

      const newState = EditorState.push(
        editorState,
        newContentState,
        "remove-range"
      );
      setEditorState(RichUtils.toggleInlineStyle(newState, "BOLD"));
      return "handled";
    }

    // Handle **
    if (blockText === "**" && chars === " ") {
      const newContentState = Modifier.replaceText(
        currentContent,
        selectionState.merge({
          anchorOffset: 0,
          focusOffset: 2,
        }),
        "",
        block.getInlineStyleAt(0)
      );

      const newState = EditorState.push(
        editorState,
        newContentState,
        "remove-range"
      );
      setEditorState(RichUtils.toggleInlineStyle(newState, "HIGHLIGHT"));
      return "handled";
    }

    // Handle ***
    if (blockText === "***" && chars === " ") {
      const newContentState = Modifier.replaceText(
        currentContent,
        selectionState.merge({
          anchorOffset: 0,
          focusOffset: 3,
        }),
        "",
        block.getInlineStyleAt(0)
      );

      const newState = EditorState.push(
        editorState,
        newContentState,
        "remove-range"
      );
      setEditorState(RichUtils.toggleInlineStyle(newState, "UNDERLINE"));
      return "handled";
    }

    // Handle ```
    if (blockText === "```" && chars === " ") {
      const newContentState = Modifier.replaceText(
        currentContent,
        selectionState.merge({
          anchorOffset: 0,
          focusOffset: 3,
        }),
        "",
        block.getInlineStyleAt(0)
      );

      const newState = EditorState.push(
        editorState,
        newContentState,
        "remove-range"
      );
      setEditorState(RichUtils.toggleInlineStyle(newState, "CODEBLOCK"));
      return "handled";
    }

    return "not-handled";
  };

  // FOR INLINE STYLES
  const styleMap = {
    RED_LINE: {
      color: "red",
    },
    UNDERLINE: {
      textDecoration: "underline",
    },
    BOLD: {
      fontWeight: "bold",
    },
    HEADING_ONE: {
      fontSize: "30px",
    },
    CODE: {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
    HIGHLIGHT: {
      color: "red",
    },
    UPPERCASE: {
      textTransform: "uppercase",
    },
    LOWERCASE: {
      textTransform: "lowercase",
    },
    CODEBLOCK: {
      fontFamily: '"fira-code", "monospace"',
      fontSize: "inherit",
      background: "#ffeff0",
      fontStyle: "italic",
      lineHeight: 1.5,
      padding: "0.3rem 0.5rem",
      borderRadius: " 0.2rem",
    },
    SUPERSCRIPT: {
      verticalAlign: "super",
      fontSize: "80%",
    },
    SUBSCRIPT: {
      verticalAlign: "sub",
      fontSize: "80%",
    },
  };

  // FOR BLOCK LEVEL STYLES(Returns CSS Class From DraftEditor.css)
  const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    switch (type) {
      case "blockQuote":
        return "superFancyBlockquote";
      case "leftAlign":
        return "leftAlign";
      case "rightAlign":
        return "rightAlign";
      case "centerAlign":
        return "centerAlign";
      case "justifyAlign":
        return "justifyAlign";
      default:
        break;
    }
  };

  const saveContent = () => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    localStorage.setItem("editorContent", JSON.stringify(rawContent));
    alert("Content saved!");
  };

  return (
    <div className="main">
      <div className="flex items-center space-x-4 rtl:space-x-reverse mb-6">
        <div className="flex-1 min-w-0">
          <h1 className="text-2xl font-extrabold">Demo editor by Draft.js</h1>
        </div>
        <div className="inline-flex items-center">
          <button
            onClick={saveContent}
            className="button px-8 py-2.5 text-center"
          >
            Save
          </button>
        </div>
      </div>
      <div className="editor-wrapper" onClick={focusEditor}>
        <Toolbar editorState={editorState} setEditorState={setEditorState} />
        <div className="editor-container">
          <Editor
            ref={editor}
            placeholder="Write Here"
            handleKeyCommand={handleKeyCommand}
            editorState={editorState}
            customStyleMap={styleMap}
            blockStyleFn={myBlockStyleFn}
            handleBeforeInput={handleBeforeInput}
            onChange={(editorState) => {
              const contentState = editorState.getCurrentContent();
              console.log(convertToRaw(contentState));
              setEditorState(editorState);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DraftEditor;
