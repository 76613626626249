import DraftEditor from './components/DraftEditor';

function App() {
  return (
    <div className="App">
      <DraftEditor />
    </div>
  );
}

export default App;
